/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BlogPostPaginateResponse } from '../models/blog-post-paginate-response';
import { BlogPostShowResponse } from '../models/blog-post-show-response';
import { BlogPostShowResponse2 } from '../models/blog-post-show-response-2';
import { deleteBlogPostRemove } from '../fn/blog-post/delete-blog-post-remove';
import { DeleteBlogPostRemove$Params } from '../fn/blog-post/delete-blog-post-remove';
import { getBlogPostIndex } from '../fn/blog-post/get-blog-post-index';
import { GetBlogPostIndex$Params } from '../fn/blog-post/get-blog-post-index';
import { getBlogPostShow } from '../fn/blog-post/get-blog-post-show';
import { GetBlogPostShow$Params } from '../fn/blog-post/get-blog-post-show';
import { patchBlogPostUpdate } from '../fn/blog-post/patch-blog-post-update';
import { PatchBlogPostUpdate$Params } from '../fn/blog-post/patch-blog-post-update';
import { postBlogPostCreate } from '../fn/blog-post/post-blog-post-create';
import { PostBlogPostCreate$Params } from '../fn/blog-post/post-blog-post-create';


/**
 * BlogPost
 */
@Injectable({ providedIn: 'root' })
export class BlogPostService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBlogPostIndex()` */
  static readonly GetBlogPostIndexPath = '/v1/blog-post';

  /**
   * list blog-posts.
   *
   * Fetch paginated list of blogPosts with base data to display links
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlogPostIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogPostIndex$Response(params?: GetBlogPostIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<BlogPostPaginateResponse>> {
    return getBlogPostIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list blog-posts.
   *
   * Fetch paginated list of blogPosts with base data to display links
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBlogPostIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogPostIndex(params?: GetBlogPostIndex$Params, context?: HttpContext): Observable<BlogPostPaginateResponse> {
    return this.getBlogPostIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlogPostPaginateResponse>): BlogPostPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postBlogPostCreate()` */
  static readonly PostBlogPostCreatePath = '/v1/blog-post';

  /**
   * add a new blog post.
   *
   * create a new blogPost with image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBlogPostCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBlogPostCreate$Response(params: PostBlogPostCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<BlogPostShowResponse>> {
    return postBlogPostCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a new blog post.
   *
   * create a new blogPost with image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postBlogPostCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postBlogPostCreate(params: PostBlogPostCreate$Params, context?: HttpContext): Observable<BlogPostShowResponse> {
    return this.postBlogPostCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlogPostShowResponse>): BlogPostShowResponse => r.body)
    );
  }

  /** Path part for operation `getBlogPostShow()` */
  static readonly GetBlogPostShowPath = '/v1/blog-post/{name}';

  /**
   * show a blog post.
   *
   * Fetch a single blogPost
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlogPostShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogPostShow$Response(params: GetBlogPostShow$Params, context?: HttpContext): Observable<StrictHttpResponse<BlogPostShowResponse2>> {
    return getBlogPostShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a blog post.
   *
   * Fetch a single blogPost
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBlogPostShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlogPostShow(params: GetBlogPostShow$Params, context?: HttpContext): Observable<BlogPostShowResponse2> {
    return this.getBlogPostShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlogPostShowResponse2>): BlogPostShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteBlogPostRemove()` */
  static readonly DeleteBlogPostRemovePath = '/v1/blog-post/{name}';

  /**
   * remove a blog post.
   *
   * Mark blogPost as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBlogPostRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlogPostRemove$Response(params: DeleteBlogPostRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<BlogPostShowResponse2>> {
    return deleteBlogPostRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a blog post.
   *
   * Mark blogPost as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBlogPostRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBlogPostRemove(params: DeleteBlogPostRemove$Params, context?: HttpContext): Observable<BlogPostShowResponse2> {
    return this.deleteBlogPostRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlogPostShowResponse2>): BlogPostShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchBlogPostUpdate()` */
  static readonly PatchBlogPostUpdatePath = '/v1/blog-post/{name}';

  /**
   * update a blog post.
   *
   * update blogPost and new image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBlogPostUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBlogPostUpdate$Response(params: PatchBlogPostUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<BlogPostShowResponse2>> {
    return patchBlogPostUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a blog post.
   *
   * update blogPost and new image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBlogPostUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBlogPostUpdate(params: PatchBlogPostUpdate$Params, context?: HttpContext): Observable<BlogPostShowResponse2> {
    return this.patchBlogPostUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlogPostShowResponse2>): BlogPostShowResponse2 => r.body)
    );
  }

}
