/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteCampDiscountRemove } from '../fn/discount/delete-camp-discount-remove';
import { DeleteCampDiscountRemove$Params } from '../fn/discount/delete-camp-discount-remove';
import { deleteDiscountCouponRemove } from '../fn/discount/delete-discount-coupon-remove';
import { DeleteDiscountCouponRemove$Params } from '../fn/discount/delete-discount-coupon-remove';
import { deleteDiscountRemove } from '../fn/discount/delete-discount-remove';
import { DeleteDiscountRemove$Params } from '../fn/discount/delete-discount-remove';
import { DiscountCampaignPaginateResponse } from '../models/discount-campaign-paginate-response';
import { DiscountCampaignShowResponse } from '../models/discount-campaign-show-response';
import { DiscountCouponPaginateResponse } from '../models/discount-coupon-paginate-response';
import { DiscountCouponShowResponse } from '../models/discount-coupon-show-response';
import { getDiscountCouponIndex } from '../fn/discount/get-discount-coupon-index';
import { GetDiscountCouponIndex$Params } from '../fn/discount/get-discount-coupon-index';
import { getDiscountCouponShow } from '../fn/discount/get-discount-coupon-show';
import { GetDiscountCouponShow$Params } from '../fn/discount/get-discount-coupon-show';
import { getDiscountIndex } from '../fn/discount/get-discount-index';
import { GetDiscountIndex$Params } from '../fn/discount/get-discount-index';
import { getDiscountShow } from '../fn/discount/get-discount-show';
import { GetDiscountShow$Params } from '../fn/discount/get-discount-show';
import { patchDiscountUpdate } from '../fn/discount/patch-discount-update';
import { PatchDiscountUpdate$Params } from '../fn/discount/patch-discount-update';
import { postCampDiscountCreate } from '../fn/discount/post-camp-discount-create';
import { PostCampDiscountCreate$Params } from '../fn/discount/post-camp-discount-create';
import { postDiscountCouponCreate } from '../fn/discount/post-discount-coupon-create';
import { PostDiscountCouponCreate$Params } from '../fn/discount/post-discount-coupon-create';
import { postDiscountCreate } from '../fn/discount/post-discount-create';
import { PostDiscountCreate$Params } from '../fn/discount/post-discount-create';


/**
 * Discount
 */
@Injectable({ providedIn: 'root' })
export class DiscountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDiscountIndex()` */
  static readonly GetDiscountIndexPath = '/v1/discount';

  /**
   * list discounts.
   *
   * Fetch paginated list of discounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountIndex$Response(params?: GetDiscountIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCampaignPaginateResponse>> {
    return getDiscountIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list discounts.
   *
   * Fetch paginated list of discounts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountIndex(params?: GetDiscountIndex$Params, context?: HttpContext): Observable<DiscountCampaignPaginateResponse> {
    return this.getDiscountIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignPaginateResponse>): DiscountCampaignPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postDiscountCreate()` */
  static readonly PostDiscountCreatePath = '/v1/discount';

  /**
   * store a new discount.
   *
   * Add a new discount campaign
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDiscountCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCreate$Response(params: PostDiscountCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {
    return postDiscountCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * store a new discount.
   *
   * Add a new discount campaign
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDiscountCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCreate(params: PostDiscountCreate$Params, context?: HttpContext): Observable<DiscountCampaignShowResponse> {
    return this.postDiscountCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>): DiscountCampaignShowResponse => r.body)
    );
  }

  /** Path part for operation `getDiscountShow()` */
  static readonly GetDiscountShowPath = '/v1/discount/{id}';

  /**
   * show a discount.
   *
   * Fetch a single discount campaign
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountShow$Response(params: GetDiscountShow$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {
    return getDiscountShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a discount.
   *
   * Fetch a single discount campaign
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountShow(params: GetDiscountShow$Params, context?: HttpContext): Observable<DiscountCampaignShowResponse> {
    return this.getDiscountShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>): DiscountCampaignShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteDiscountRemove()` */
  static readonly DeleteDiscountRemovePath = '/v1/discount/{id}';

  /**
   * remove a discount.
   *
   * mark discount as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDiscountRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountRemove$Response(params: DeleteDiscountRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {
    return deleteDiscountRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a discount.
   *
   * mark discount as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDiscountRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountRemove(params: DeleteDiscountRemove$Params, context?: HttpContext): Observable<DiscountCampaignShowResponse> {
    return this.deleteDiscountRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>): DiscountCampaignShowResponse => r.body)
    );
  }

  /** Path part for operation `patchDiscountUpdate()` */
  static readonly PatchDiscountUpdatePath = '/v1/discount/{id}';

  /**
   * update a discount.
   *
   * update discount details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDiscountUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscountUpdate$Response(params: PatchDiscountUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCampaignShowResponse>> {
    return patchDiscountUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a discount.
   *
   * update discount details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDiscountUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchDiscountUpdate(params: PatchDiscountUpdate$Params, context?: HttpContext): Observable<DiscountCampaignShowResponse> {
    return this.patchDiscountUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCampaignShowResponse>): DiscountCampaignShowResponse => r.body)
    );
  }

  /** Path part for operation `postCampDiscountCreate()` */
  static readonly PostCampDiscountCreatePath = '/v1/camp/{camp}/discount/{id}';

  /**
   * add discount to camp.
   *
   * Add a an existing discount-campaign to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampDiscountCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCampDiscountCreate$Response(params: PostCampDiscountCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postCampDiscountCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add discount to camp.
   *
   * Add a an existing discount-campaign to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampDiscountCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCampDiscountCreate(params: PostCampDiscountCreate$Params, context?: HttpContext): Observable<void> {
    return this.postCampDiscountCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteCampDiscountRemove()` */
  static readonly DeleteCampDiscountRemovePath = '/v1/camp/{camp}/discount/{id}';

  /**
   * remove discount from camp.
   *
   * Remove discount-campaign from a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampDiscountRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampDiscountRemove$Response(params: DeleteCampDiscountRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCampDiscountRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove discount from camp.
   *
   * Remove discount-campaign from a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampDiscountRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampDiscountRemove(params: DeleteCampDiscountRemove$Params, context?: HttpContext): Observable<void> {
    return this.deleteCampDiscountRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getDiscountCouponIndex()` */
  static readonly GetDiscountCouponIndexPath = '/v1/discount-coupon';

  /**
   * list coupons.
   *
   * Fetch paginated list of discount coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountCouponIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponIndex$Response(params?: GetDiscountCouponIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCouponPaginateResponse>> {
    return getDiscountCouponIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list coupons.
   *
   * Fetch paginated list of discount coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountCouponIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponIndex(params?: GetDiscountCouponIndex$Params, context?: HttpContext): Observable<DiscountCouponPaginateResponse> {
    return this.getDiscountCouponIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCouponPaginateResponse>): DiscountCouponPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postDiscountCouponCreate()` */
  static readonly PostDiscountCouponCreatePath = '/v1/discount-coupon';

  /**
   * store multiple new coupons.
   *
   * Add multiple new coupons to an existing campaign.
   * Use `allowedCharacters` and `length` to customize the coupon value.
   * Use `amount` to define the total number of coupons to create.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDiscountCouponCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCouponCreate$Response(params: PostDiscountCouponCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCouponPaginateResponse>> {
    return postDiscountCouponCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * store multiple new coupons.
   *
   * Add multiple new coupons to an existing campaign.
   * Use `allowedCharacters` and `length` to customize the coupon value.
   * Use `amount` to define the total number of coupons to create.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDiscountCouponCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDiscountCouponCreate(params: PostDiscountCouponCreate$Params, context?: HttpContext): Observable<DiscountCouponPaginateResponse> {
    return this.postDiscountCouponCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCouponPaginateResponse>): DiscountCouponPaginateResponse => r.body)
    );
  }

  /** Path part for operation `getDiscountCouponShow()` */
  static readonly GetDiscountCouponShowPath = '/v1/camp/{camp}/discount-coupon/{value}';

  /**
   * show a coupon.
   *
   * Fetch a single unused DiscountCoupon by value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDiscountCouponShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponShow$Response(params: GetDiscountCouponShow$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCouponShowResponse>> {
    return getDiscountCouponShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a coupon.
   *
   * Fetch a single unused DiscountCoupon by value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDiscountCouponShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDiscountCouponShow(params: GetDiscountCouponShow$Params, context?: HttpContext): Observable<DiscountCouponShowResponse> {
    return this.getDiscountCouponShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCouponShowResponse>): DiscountCouponShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteDiscountCouponRemove()` */
  static readonly DeleteDiscountCouponRemovePath = '/v1/discount-coupon/{value}';

  /**
   * remove a coupon.
   *
   * mark coupon as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDiscountCouponRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountCouponRemove$Response(params: DeleteDiscountCouponRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<DiscountCouponShowResponse>> {
    return deleteDiscountCouponRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a coupon.
   *
   * mark coupon as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDiscountCouponRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDiscountCouponRemove(params: DeleteDiscountCouponRemove$Params, context?: HttpContext): Observable<DiscountCouponShowResponse> {
    return this.deleteDiscountCouponRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<DiscountCouponShowResponse>): DiscountCouponShowResponse => r.body)
    );
  }

}
