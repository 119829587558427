/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteGalleryItemRemove } from '../fn/gallery/delete-gallery-item-remove';
import { DeleteGalleryItemRemove$Params } from '../fn/gallery/delete-gallery-item-remove';
import { deleteGalleryRemove } from '../fn/gallery/delete-gallery-remove';
import { DeleteGalleryRemove$Params } from '../fn/gallery/delete-gallery-remove';
import { GalleryPaginateResponse } from '../models/gallery-paginate-response';
import { GalleryShowResponse } from '../models/gallery-show-response';
import { GalleryShowResponse2 } from '../models/gallery-show-response-2';
import { getGalleryIndex } from '../fn/gallery/get-gallery-index';
import { GetGalleryIndex$Params } from '../fn/gallery/get-gallery-index';
import { getGalleryShow } from '../fn/gallery/get-gallery-show';
import { GetGalleryShow$Params } from '../fn/gallery/get-gallery-show';
import { patchGalleryUpdate } from '../fn/gallery/patch-gallery-update';
import { PatchGalleryUpdate$Params } from '../fn/gallery/patch-gallery-update';
import { postGalleryCreate } from '../fn/gallery/post-gallery-create';
import { PostGalleryCreate$Params } from '../fn/gallery/post-gallery-create';
import { postGalleryItemCreate } from '../fn/gallery/post-gallery-item-create';
import { PostGalleryItemCreate$Params } from '../fn/gallery/post-gallery-item-create';


/**
 * Gallery
 */
@Injectable({ providedIn: 'root' })
export class GalleryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getGalleryIndex()` */
  static readonly GetGalleryIndexPath = '/v1/gallery';

  /**
   * list image galleries.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGalleryIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryIndex$Response(params?: GetGalleryIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<GalleryPaginateResponse>> {
    return getGalleryIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list image galleries.
   *
   * Fetch a paginated list of galleries.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGalleryIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryIndex(params?: GetGalleryIndex$Params, context?: HttpContext): Observable<GalleryPaginateResponse> {
    return this.getGalleryIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<GalleryPaginateResponse>): GalleryPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postGalleryCreate()` */
  static readonly PostGalleryCreatePath = '/v1/gallery';

  /**
   * store a new gallery.
   *
   * create a new empty gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGalleryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryCreate$Response(params: PostGalleryCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<GalleryShowResponse>> {
    return postGalleryCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * store a new gallery.
   *
   * create a new empty gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postGalleryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryCreate(params: PostGalleryCreate$Params, context?: HttpContext): Observable<GalleryShowResponse> {
    return this.postGalleryCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse>): GalleryShowResponse => r.body)
    );
  }

  /** Path part for operation `getGalleryShow()` */
  static readonly GetGalleryShowPath = '/v1/gallery/{name}';

  /**
   * show a gallery.
   *
   * Fetch a single image gallery with images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGalleryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryShow$Response(params: GetGalleryShow$Params, context?: HttpContext): Observable<StrictHttpResponse<GalleryShowResponse2>> {
    return getGalleryShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a gallery.
   *
   * Fetch a single image gallery with images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGalleryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGalleryShow(params: GetGalleryShow$Params, context?: HttpContext): Observable<GalleryShowResponse2> {
    return this.getGalleryShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>): GalleryShowResponse2 => r.body)
    );
  }

  /** Path part for operation `deleteGalleryRemove()` */
  static readonly DeleteGalleryRemovePath = '/v1/gallery/{name}';

  /**
   * remove a gallery.
   *
   * Mark a gallery as removed. Images will persist.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGalleryRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryRemove$Response(params: DeleteGalleryRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<GalleryShowResponse2>> {
    return deleteGalleryRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a gallery.
   *
   * Mark a gallery as removed. Images will persist.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGalleryRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryRemove(params: DeleteGalleryRemove$Params, context?: HttpContext): Observable<GalleryShowResponse2> {
    return this.deleteGalleryRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>): GalleryShowResponse2 => r.body)
    );
  }

  /** Path part for operation `patchGalleryUpdate()` */
  static readonly PatchGalleryUpdatePath = '/v1/gallery/{name}';

  /**
   * update a gallery.
   *
   * Update gallery details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchGalleryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchGalleryUpdate$Response(params: PatchGalleryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<GalleryShowResponse2>> {
    return patchGalleryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a gallery.
   *
   * Update gallery details
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchGalleryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchGalleryUpdate(params: PatchGalleryUpdate$Params, context?: HttpContext): Observable<GalleryShowResponse2> {
    return this.patchGalleryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<GalleryShowResponse2>): GalleryShowResponse2 => r.body)
    );
  }

  /** Path part for operation `postGalleryItemCreate()` */
  static readonly PostGalleryItemCreatePath = '/v1/gallery/{name}/item';

  /**
   * store a new gallery-item.
   *
   * add item to a gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGalleryItemCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryItemCreate$Response(params: PostGalleryItemCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postGalleryItemCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * store a new gallery-item.
   *
   * add item to a gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postGalleryItemCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postGalleryItemCreate(params: PostGalleryItemCreate$Params, context?: HttpContext): Observable<void> {
    return this.postGalleryItemCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteGalleryItemRemove()` */
  static readonly DeleteGalleryItemRemovePath = '/v1/gallery/{name}/item/{id}';

  /**
   * remove a gallery-item.
   *
   * Remove an item from a gallery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGalleryItemRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryItemRemove$Response(params: DeleteGalleryItemRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteGalleryItemRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a gallery-item.
   *
   * Remove an item from a gallery
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteGalleryItemRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGalleryItemRemove(params: DeleteGalleryItemRemove$Params, context?: HttpContext): Observable<void> {
    return this.deleteGalleryItemRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
