/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FileStoreResponse } from '../models/file-store-response';
import { postFileCreate } from '../fn/file/post-file-create';
import { PostFileCreate$Params } from '../fn/file/post-file-create';


/**
 * File
 */
@Injectable({ providedIn: 'root' })
export class FileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postFileCreate()` */
  static readonly PostFileCreatePath = '/v1/file';

  /**
   * Upload a file.
   *
   * Save a file to the served directory of https://kinder-ferienlager.com/uploads
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFileCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postFileCreate$Response(params: PostFileCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileStoreResponse>> {
    return postFileCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload a file.
   *
   * Save a file to the served directory of https://kinder-ferienlager.com/uploads
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFileCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postFileCreate(params: PostFileCreate$Params, context?: HttpContext): Observable<FileStoreResponse> {
    return this.postFileCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileStoreResponse>): FileStoreResponse => r.body)
    );
  }

}
