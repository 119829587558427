/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CampTripPaginateResponse } from '../models/camp-trip-paginate-response';
import { CampTripShowResponse } from '../models/camp-trip-show-response';
import { deleteCampTripRemove } from '../fn/camp-trip/delete-camp-trip-remove';
import { DeleteCampTripRemove$Params } from '../fn/camp-trip/delete-camp-trip-remove';
import { deleteTripProgramRemove } from '../fn/camp-trip/delete-trip-program-remove';
import { DeleteTripProgramRemove$Params } from '../fn/camp-trip/delete-trip-program-remove';
import { getCampTripIndex } from '../fn/camp-trip/get-camp-trip-index';
import { GetCampTripIndex$Params } from '../fn/camp-trip/get-camp-trip-index';
import { getCampTripShow } from '../fn/camp-trip/get-camp-trip-show';
import { GetCampTripShow$Params } from '../fn/camp-trip/get-camp-trip-show';
import { patchCampTripUpdate } from '../fn/camp-trip/patch-camp-trip-update';
import { PatchCampTripUpdate$Params } from '../fn/camp-trip/patch-camp-trip-update';
import { postCampTripCopy } from '../fn/camp-trip/post-camp-trip-copy';
import { PostCampTripCopy$Params } from '../fn/camp-trip/post-camp-trip-copy';
import { postCampTripCreate } from '../fn/camp-trip/post-camp-trip-create';
import { PostCampTripCreate$Params } from '../fn/camp-trip/post-camp-trip-create';
import { postTripProgramCreate } from '../fn/camp-trip/post-trip-program-create';
import { PostTripProgramCreate$Params } from '../fn/camp-trip/post-trip-program-create';


/**
 * CampTrip
 */
@Injectable({ providedIn: 'root' })
export class CampTripService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCampTripIndex()` */
  static readonly GetCampTripIndexPath = '/v1/camp-trip';

  /**
   * list trips.
   *
   * Fetch paginated list of camp trips
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripIndex$Response(params?: GetCampTripIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripPaginateResponse>> {
    return getCampTripIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list trips.
   *
   * Fetch paginated list of camp trips
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripIndex(params?: GetCampTripIndex$Params, context?: HttpContext): Observable<CampTripPaginateResponse> {
    return this.getCampTripIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripPaginateResponse>): CampTripPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postCampTripCreate()` */
  static readonly PostCampTripCreatePath = '/v1/camp-trip';

  /**
   * create trip.
   *
   * Create a new camp-trip and connect it to a camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCreate$Response(params: PostCampTripCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripShowResponse>> {
    return postCampTripCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create trip.
   *
   * Create a new camp-trip and connect it to a camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCreate(params: PostCampTripCreate$Params, context?: HttpContext): Observable<CampTripShowResponse> {
    return this.postCampTripCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>): CampTripShowResponse => r.body)
    );
  }

  /** Path part for operation `getCampTripShow()` */
  static readonly GetCampTripShowPath = '/v1/camp-trip/{id}';

  /**
   * show a trip.
   *
   * Fetch a single camp-trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampTripShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripShow$Response(params: GetCampTripShow$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripShowResponse>> {
    return getCampTripShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a trip.
   *
   * Fetch a single camp-trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampTripShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampTripShow(params: GetCampTripShow$Params, context?: HttpContext): Observable<CampTripShowResponse> {
    return this.getCampTripShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>): CampTripShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteCampTripRemove()` */
  static readonly DeleteCampTripRemovePath = '/v1/camp-trip/{id}';

  /**
   * remove trip.
   *
   * Mark trip as removed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampTripRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripRemove$Response(params: DeleteCampTripRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripShowResponse>> {
    return deleteCampTripRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove trip.
   *
   * Mark trip as removed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampTripRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampTripRemove(params: DeleteCampTripRemove$Params, context?: HttpContext): Observable<CampTripShowResponse> {
    return this.deleteCampTripRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>): CampTripShowResponse => r.body)
    );
  }

  /** Path part for operation `patchCampTripUpdate()` */
  static readonly PatchCampTripUpdatePath = '/v1/camp-trip/{id}';

  /**
   * update a trip.
   *
   * update a trip but not the camp or other entities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCampTripUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripUpdate$Response(params: PatchCampTripUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripShowResponse>> {
    return patchCampTripUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update a trip.
   *
   * update a trip but not the camp or other entities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCampTripUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCampTripUpdate(params: PatchCampTripUpdate$Params, context?: HttpContext): Observable<CampTripShowResponse> {
    return this.patchCampTripUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>): CampTripShowResponse => r.body)
    );
  }

  /** Path part for operation `postCampTripCopy()` */
  static readonly PostCampTripCopyPath = '/v1/camp-trip/{id}/copy';

  /**
   * copy trip.
   *
   * copy a trip to another camp (including locations)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampTripCopy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCopy$Response(params: PostCampTripCopy$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripShowResponse>> {
    return postCampTripCopy(this.http, this.rootUrl, params, context);
  }

  /**
   * copy trip.
   *
   * copy a trip to another camp (including locations)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampTripCopy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampTripCopy(params: PostCampTripCopy$Params, context?: HttpContext): Observable<CampTripShowResponse> {
    return this.postCampTripCopy$Response(params, context).pipe(
      map((r: StrictHttpResponse<CampTripShowResponse>): CampTripShowResponse => r.body)
    );
  }

  /** Path part for operation `postTripProgramCreate()` */
  static readonly PostTripProgramCreatePath = '/v1/camp-trip/{trip}/program';

  /**
   * add a program to a trip.
   *
   * Connect an existing program to an exisiting trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTripProgramCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTripProgramCreate$Response(params: PostTripProgramCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postTripProgramCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a program to a trip.
   *
   * Connect an existing program to an exisiting trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postTripProgramCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTripProgramCreate(params: PostTripProgramCreate$Params, context?: HttpContext): Observable<void> {
    return this.postTripProgramCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteTripProgramRemove()` */
  static readonly DeleteTripProgramRemovePath = '/v1/camp-trip/{trip}/program/{program}';

  /**
   * remove a program from a trip.
   *
   * Disconnect an existing program from an exisiting trip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTripProgramRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTripProgramRemove$Response(params: DeleteTripProgramRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTripProgramRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a program from a trip.
   *
   * Disconnect an existing program from an exisiting trip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTripProgramRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTripProgramRemove(params: DeleteTripProgramRemove$Params, context?: HttpContext): Observable<void> {
    return this.deleteTripProgramRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
