/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteCampRegionRemove } from '../fn/camp-region/delete-camp-region-remove';
import { DeleteCampRegionRemove$Params } from '../fn/camp-region/delete-camp-region-remove';
import { postCampRegionCreate } from '../fn/camp-region/post-camp-region-create';
import { PostCampRegionCreate$Params } from '../fn/camp-region/post-camp-region-create';


/**
 * CampRegion
 */
@Injectable({ providedIn: 'root' })
export class CampRegionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postCampRegionCreate()` */
  static readonly PostCampRegionCreatePath = '/v1/camp/{camp}/region';

  /**
   * add a region to a camp.
   *
   * Connect an existing region to an exisiting camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCampRegionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampRegionCreate$Response(params: PostCampRegionCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return postCampRegionCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * add a region to a camp.
   *
   * Connect an existing region to an exisiting camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCampRegionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCampRegionCreate(params: PostCampRegionCreate$Params, context?: HttpContext): Observable<void> {
    return this.postCampRegionCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteCampRegionRemove()` */
  static readonly DeleteCampRegionRemovePath = '/v1/camp-region/{camp}/region/{region}';

  /**
   * remove a region from a camp.
   *
   * Disconnect an existing region from an exisiting camp
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCampRegionRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRegionRemove$Response(params: DeleteCampRegionRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCampRegionRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove a region from a camp.
   *
   * Disconnect an existing region from an exisiting camp
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCampRegionRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCampRegionRemove(params: DeleteCampRegionRemove$Params, context?: HttpContext): Observable<void> {
    return this.deleteCampRegionRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
