/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthService } from './services/auth.service';
import { BlogPostService } from './services/blog-post.service';
import { CampService } from './services/camp.service';
import { CampCategoryService } from './services/camp-category.service';
import { CampProgramService } from './services/camp-program.service';
import { CampRegionService } from './services/camp-region.service';
import { CampTripService } from './services/camp-trip.service';
import { CampTripLocationService } from './services/camp-trip-location.service';
import { CustomerService } from './services/customer.service';
import { DiscountService } from './services/discount.service';
import { FileService } from './services/file.service';
import { GalleryService } from './services/gallery.service';
import { ApiService } from './services/api.service';
import { ImageService } from './services/image.service';
import { LocationService } from './services/location.service';
import { OrderService } from './services/order.service';
import { RegionService } from './services/region.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthService,
    BlogPostService,
    CampService,
    CampCategoryService,
    CampProgramService,
    CampRegionService,
    CampTripService,
    CampTripLocationService,
    CustomerService,
    DiscountService,
    FileService,
    GalleryService,
    ApiService,
    ImageService,
    LocationService,
    OrderService,
    RegionService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
