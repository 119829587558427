import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  name: 'test',
  apiUrl: 'https://api.kidfl.m12n.dev',
  lightGallerylicenseKey: 'D9FE52E2-DD13-4A9D-BB1D-9EB2E06B31ED',
  features: {
    ferienlagerFinder: true,
  },
};
