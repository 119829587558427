/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteImageRemove } from '../fn/image/delete-image-remove';
import { DeleteImageRemove$Params } from '../fn/image/delete-image-remove';
import { getImageIndex } from '../fn/image/get-image-index';
import { GetImageIndex$Params } from '../fn/image/get-image-index';
import { getImageShow } from '../fn/image/get-image-show';
import { GetImageShow$Params } from '../fn/image/get-image-show';
import { ImagePaginateResponse } from '../models/image-paginate-response';
import { ImageShowResponse } from '../models/image-show-response';
import { patchImageUpdate } from '../fn/image/patch-image-update';
import { PatchImageUpdate$Params } from '../fn/image/patch-image-update';
import { postImageCreate } from '../fn/image/post-image-create';
import { PostImageCreate$Params } from '../fn/image/post-image-create';


/**
 * Image
 */
@Injectable({ providedIn: 'root' })
export class ImageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getImageIndex()` */
  static readonly GetImageIndexPath = '/v1/image';

  /**
   * list images.
   *
   * Fetch a paginated list of images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageIndex$Response(params?: GetImageIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<ImagePaginateResponse>> {
    return getImageIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list images.
   *
   * Fetch a paginated list of images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageIndex(params?: GetImageIndex$Params, context?: HttpContext): Observable<ImagePaginateResponse> {
    return this.getImageIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImagePaginateResponse>): ImagePaginateResponse => r.body)
    );
  }

  /** Path part for operation `postImageCreate()` */
  static readonly PostImageCreatePath = '/v1/image';

  /**
   * store a new image.
   *
   * Create a new image & upload it to the cdn if no `cdnBasePath` is provided.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postImageCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postImageCreate$Response(params: PostImageCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageShowResponse>> {
    return postImageCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * store a new image.
   *
   * Create a new image & upload it to the cdn if no `cdnBasePath` is provided.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postImageCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postImageCreate(params: PostImageCreate$Params, context?: HttpContext): Observable<ImageShowResponse> {
    return this.postImageCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>): ImageShowResponse => r.body)
    );
  }

  /** Path part for operation `getImageShow()` */
  static readonly GetImageShowPath = '/v1/image/{id}';

  /**
   * show a image.
   *
   * Fetch a single image with contacts and addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShow$Response(params: GetImageShow$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageShowResponse>> {
    return getImageShow(this.http, this.rootUrl, params, context);
  }

  /**
   * show a image.
   *
   * Fetch a single image with contacts and addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getImageShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageShow(params: GetImageShow$Params, context?: HttpContext): Observable<ImageShowResponse> {
    return this.getImageShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>): ImageShowResponse => r.body)
    );
  }

  /** Path part for operation `deleteImageRemove()` */
  static readonly DeleteImageRemovePath = '/v1/image/{id}';

  /**
   * remove an image.
   *
   * Mark image as removed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteImageRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageRemove$Response(params: DeleteImageRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageShowResponse>> {
    return deleteImageRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * remove an image.
   *
   * Mark image as removed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteImageRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteImageRemove(params: DeleteImageRemove$Params, context?: HttpContext): Observable<ImageShowResponse> {
    return this.deleteImageRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>): ImageShowResponse => r.body)
    );
  }

  /** Path part for operation `patchImageUpdate()` */
  static readonly PatchImageUpdatePath = '/v1/image/{id}';

  /**
   * update an image.
   *
   * update image & upload to cdn if necessary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchImageUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchImageUpdate$Response(params: PatchImageUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ImageShowResponse>> {
    return patchImageUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * update an image.
   *
   * update image & upload to cdn if necessary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchImageUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchImageUpdate(params: PatchImageUpdate$Params, context?: HttpContext): Observable<ImageShowResponse> {
    return this.patchImageUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImageShowResponse>): ImageShowResponse => r.body)
    );
  }

}
