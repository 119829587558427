/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRegionIndex } from '../fn/region/get-region-index';
import { GetRegionIndex$Params } from '../fn/region/get-region-index';
import { postRegionCreate } from '../fn/region/post-region-create';
import { PostRegionCreate$Params } from '../fn/region/post-region-create';
import { RegionPaginateResponse } from '../models/region-paginate-response';
import { RegionShowResponse } from '../models/region-show-response';


/**
 * Region
 */
@Injectable({ providedIn: 'root' })
export class RegionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRegionIndex()` */
  static readonly GetRegionIndexPath = '/v1/region';

  /**
   * list regions.
   *
   * Fetch paginated list of regions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegionIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionIndex$Response(params?: GetRegionIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<RegionPaginateResponse>> {
    return getRegionIndex(this.http, this.rootUrl, params, context);
  }

  /**
   * list regions.
   *
   * Fetch paginated list of regions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRegionIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegionIndex(params?: GetRegionIndex$Params, context?: HttpContext): Observable<RegionPaginateResponse> {
    return this.getRegionIndex$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegionPaginateResponse>): RegionPaginateResponse => r.body)
    );
  }

  /** Path part for operation `postRegionCreate()` */
  static readonly PostRegionCreatePath = '/v1/region';

  /**
   * create a new region.
   *
   * create a new region
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postRegionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRegionCreate$Response(params: PostRegionCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<RegionShowResponse>> {
    return postRegionCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * create a new region.
   *
   * create a new region
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postRegionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRegionCreate(params: PostRegionCreate$Params, context?: HttpContext): Observable<RegionShowResponse> {
    return this.postRegionCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegionShowResponse>): RegionShowResponse => r.body)
    );
  }

}
