/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CampTripPaginateResponse } from '../../models/camp-trip-paginate-response';

export interface GetCampTripIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field (array possible)
 */
  'orderBy[]'?: Array<'start:asc' | 'start:desc' | 'end:asc' | 'end:desc'>;

/**
 * Filter trip id
 */
  id?: number;

/**
 * Filter trip start
 */
  start?: string;

/**
 * Filter trip end
 */
  end?: string;

/**
 * Filter camp id
 */
  camp?: number;

/**
 * Filter camp category name
 */
  category?: string;

/**
 * Filter by trip state. Comma separated allowed. <br>0 => AVAILABLE<br>1 => FEW_SPACES_LEFT<br>2 => SOLD_OUT<br>3 => ONLY_PHONE
 */
  state?: string;

/**
 * Filter departure location id
 */
  departureLocation?: string;

/**
 * Filter return location id
 */
  returnLocation?: string;

/**
 * Filter program id
 */
  program?: string;

/**
 * Filter by program ids comma separated
 */
  additionalProgram?: string;

/**
 * Filter by bookingCode
 */
  bookingCode?: string;

/**
 * Filter by trip duration (diff between start/end)
 */
  duration?: number;

/**
 * Filter by allowed age of related camp
 */
  age?: number;

/**
 * Filter by CampRegion id
 */
  region?: string;
}

export function getCampTripIndex(http: HttpClient, rootUrl: string, params?: GetCampTripIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampTripPaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getCampTripIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderBy[]', params['orderBy[]'], {});
    rb.query('id', params.id, {});
    rb.query('start', params.start, {});
    rb.query('end', params.end, {});
    rb.query('camp', params.camp, {});
    rb.query('category', params.category, {});
    rb.query('state', params.state, {});
    rb.query('departureLocation', params.departureLocation, {});
    rb.query('returnLocation', params.returnLocation, {});
    rb.query('program', params.program, {});
    rb.query('additionalProgram', params.additionalProgram, {});
    rb.query('bookingCode', params.bookingCode, {});
    rb.query('duration', params.duration, {});
    rb.query('age', params.age, {});
    rb.query('region', params.region, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CampTripPaginateResponse>;
    })
  );
}

getCampTripIndex.PATH = '/v1/camp-trip';
